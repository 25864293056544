// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$gifty-web-app-primary: mat.define-palette(mat.$indigo-palette);
$gifty-web-app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$gifty-web-app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$gifty-web-app-theme: mat.define-light-theme((color: (primary: $gifty-web-app-primary,
                accent: $gifty-web-app-accent,
                warn: $gifty-web-app-warn,
            )));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($gifty-web-app-theme);

::ng-deep .mat-form-field-flex>.mat-form-field-infix {
    padding: 0.4em 0px !important;
}

::ng-deep .mat-form-field-label-wrapper {
    top: -1.5em;
}

::ng-deep .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    transform: translateY(-1.1em) scale(.75);
    width: 133.33333%;
}

.mat-dense-field {
    @include mat.form-field-density(-3);

}

.cdk-overlay-container {
    z-index: 99999;
}

.icon-2x {
    transform: scale(1.5);
}

/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
// Required
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

// Fonts
@import url('assets/css/fonts_googleapis_css2_family_acme.css');
@import url('assets/css/fonts_googleapis_css2_family_lexend.css');
@import 'animate.css';

// stylelint-disable value-keyword-case
$font-family-sans-serif: 'Lexend', sans-serif;
$font-family-monospace: 'Acme', sans-serif;

// stylelint-enable value-keyword-case
$font-family-base: var(--#{$variable-prefix}font-sans-serif);
$font-family-code: var(--#{$variable-prefix}font-monospace);

.primary-font {
    font-family: $font-family-base;
}

.secondary-font {
    font-family: $font-family-code;
}

// Theme color palette override
$blue: #745C97;
$indigo: #39375B;
$purple: #6f42c1;
$pink: #F38173;
$red: #C43B2C;
$orange: #fd7e14;
$yellow: #FFBC42;
$green: #289FA0;
$teal: #20c997;
$cyan: #0dcaf0;
$white: #F8F1F2;
$black: #3F3448;
$gray: #BEB7C4;
$secondary-pink: #FBADAB;

$colors: (
    "blue": $blue,
    "indigo": $indigo,
    "purple": $purple,
    "pink": $pink,
    "red": $red,
    "orange": $orange,
    "yellow": $yellow,
    "green": $green,
    "teal": $teal,
    "cyan": $cyan,
    "white": $white,
    "black": $black,
    "gray": $gray,
    "gray-dark": $gray-800,
    "secondary-pink": $secondary-pink,
);

$primary: $blue;
$danger: $red;
$secondary: $pink;
$warning: $yellow;
$light: $white;
$dark: $black;
$success: $green;


$theme-colors: (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "dark": $dark,
    "secondary-pink": $secondary-pink,
);

$zindex-dropdown: 1000;
$zindex-sticky: 1020;
$zindex-fixed: 1030;
$zindex-offcanvas-backdrop: 1040;
$zindex-offcanvas: 1045;
$zindex-modal-backdrop: 1050;
$zindex-modal: 1055;
$zindex-popover: 1070;
$zindex-tooltip: 1080;
$zindex-toast: 1090;

// Nav
.nav-tabs {
    a {
        --bs-nav-link-hover-color: var(--bs-gray-500);
        --bs-nav-link-color: var(--bs-gray-500);
    }
}

// Z-index
.z-index-n1 {
    z-index: -1 !important;
}

.z-index-1 {
    z-index: 1 !important;
}

.z-index-2 {
    z-index: 2 !important;
}

// Border-radius
.rounded-top-right-5 {
    border-top-right-radius: $border-radius-2xl;
}

textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type=text]:focus,
input[type=password]:focus,
input[type=email]:focus,
input[type=number]:focus,
[type=text].form-control:focus,
[type=password].form-control:focus,
[type=email].form-control:focus,
[type=tel].form-control:focus,
[contenteditable].form-control:focus,
.form-control:focus,
.form-select:focus,
select:focus {
    box-shadow: none !important;
    box-shadow: none !important;
    outline: 0 !important;
}

// Rewrite overridder theme variables
@import "node_modules/bootstrap/scss/bootstrap";

// Icon Styles
.bi-1x {
    width: 1.5 * $font-size-base;
    height: 1.5 * $font-size-base;
}

.bi-2x {
    width: 2 * $font-size-base;
    height: 2 * $font-size-base;
}

#xgy-nav-mini-cart-button {
    .badge {
        font-size: $font-size-sm * .75;
    }
}

// Dropdown
.dropdown-toggle::after {
    display: none;
}

.xgy-dropdown-toggle {
    width: 2.5 * $font-size-base;
    height: 2.5 * $font-size-base;
    vertical-align: super;
}

// Slick Global Settings
.slick-dots {
    li:not(.slick-active) {
        width: 0px;
        margin: 0 10px;
    }

    li {
        height: 0;
        width: 40px;

        &.slick-active {
            button {
                &:before {
                    content: '';
                    margin: 0 5px;
                    background-color: var(--bs-pink);
                    font-size: 12px;
                    width: 40px;
                    height: 12px;
                    color: var(--bs-pink);
                    opacity: 1;
                    -webkit-animation: scale-up-hor-left .4s cubic-bezier(.39, .575, .565, 1.000) both;
                    animation: scale-up-hor-left .4s cubic-bezier(.39, .575, .565, 1.000) both
                }
            }
        }

        button {
            &:before {
                content: '';
                background-color: var(--bs-white);
                font-size: 12px;
                width: 12px;
                height: 12px;
                color: var(--bs-white);
                border-radius: 50px;
                opacity: 1;
                box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.1);
            }
        }
    }
}

.modal-content {
    border-radius: 20px !important;
    outline-color: transparent !important;
}

// .overlary-color {
//     &::before {
//         position: absolute;
//         background-color: var(--bs-secondary);
//         width: 100%;
//         height: 100%;
//         content: '';
//         filter: opacity(0.5);
//     }
// }

// Animation
@-webkit-keyframes scale-up-hor-left {
    0% {
        -webkit-transform: scaleX(.4);
        transform: scaleX(.4);
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0
    }

    100% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0
    }
}

@keyframes scale-up-hor-left {
    0% {
        -webkit-transform: scaleX(.4);
        transform: scaleX(.4);
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0
    }

    100% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0
    }
}

.light-success-snackbar {
  color: black;
  --mdc-snackbar-container-color: #0c5305;

  .mat-simple-snackbar-action {
    color: darkgreen;
  }
}

.light-error-snackbar {
  color: black;
  --mdc-snackbar-container-color: #ce1111;

  .mat-simple-snackbar-action {
    color: #d3bb09;
  }
}

.autocomplete-container {
  box-shadow: none !important;
}

.ng-autocomplete {
  width: inherit !important;
}

.autocomplete-container .suggestions-container ul {
  max-height: 500px !important;
}

.text-justify{
    text-align: justify;
}

//importing danielmoncada angular date-time-picker styles
@import "@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";