// `sm` applies to x-small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {}

// `md` applies to small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {

    .shop-sub-header-section,
    .cart-sub-header-section,
    .checkout-step-1-sub-header-section,
    .contact-us-sub-header-section,
    .corporate-sub-header-section,
    .gifting-sub-header-section,
    .start-gifting-sub-header-section,
    .pay-completed-sub-header-section {
        height: 325px !important;
    }

    .sub-heading-title {
        font-size: 26px;
    }

    .breadcrumb {
        font-size: 14px;

        .breadcrumb-item+.breadcrumb-item {
            &::before {
                font-size: 20px !important;
            }
        }
    }
    .hotline-wrapper {
        top: calc(100% + 30px);
        min-width: 200px;
        font-size: 14px;
    }
}

// `lg` applies to medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
    .navbar-toggler-icon {
        filter: invert(1) brightness(1) contrast(100);
    }

    .shop-sub-header-section,
    .cart-sub-header-section,
    .checkout-step-1-sub-header-section,
    .contact-us-sub-header-section,
    .corporate-sub-header-section,
    .gifting-sub-header-section,
    .start-gifting-sub-header-section,
    .pay-completed-sub-header-section {
        height: 375px !important;
    }

    .overlay-search-bar {
        border-radius: var(--bs-border-radius-lg)!important;
        #floatingSearch {
            height: calc(3rem + calc(var(--bs-border-width) * 2));
            min-height: calc(3rem + calc(var(--bs-border-width) * 2));
            line-height: 1;
        }
        .form-control{
            border-radius: var(--bs-border-radius-lg)!important;
        }

        .form-floating>label {
            padding: 0.75rem 0.75rem;
        }
    }
    .hotline-wrapper {
        top: calc(100% + 30px);
        min-width: 200px;
        font-size: 14px;
    }
}

// `xl` applies to large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {}

// `xxl` applies to x-large devices (large desktops, less than 1400px)
@media (max-width: 1399.98px) {}
